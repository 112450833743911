import { BarcodeWASMResult } from "../barcode";
import { Camera } from "../camera";
import { ImageSettings } from "../imageSettings";
import { Logger } from "../logger";
import { Parser } from "../parser";
import { ScanSettings } from "../scanSettings";

// WARNING
// ==========
// The "dataCapture" function is extracted and executed in isolation as a WebWorker in the browser.
// We currently cannot use too advanced language features here as the code will not get transformed/polyfilled correctly
// by Rollup and Babel as it might refer to other externally defined variables/functions.
// This means we also cannot import and use variables from the rest of the project.
// The used language features should be compatible with (supported by) the browsers mentioned in the documentation.
// See rollup.config.js and .browserslistrc.worker for more details.
// TODO: This should be fixed...

// tslint:disable:no-any

declare const self: any;
declare const importScripts: (...urls: string[]) => Promise<void> | undefined; // Promise is used only during testing
declare const postMessage: (message: DataCaptureSentMessageData, transfer?: Transferable[]) => void;
// Defined here as we cannot use too recent typescript type definitions
declare namespace WebAssembly {
  interface Instance {
    readonly exports: any;
  }

  interface WebAssemblyInstantiatedSource {
    instance: Instance;
    // tslint:disable-next-line:no-reserved-keywords
    module: {};
  }
}

// tslint:enable:no-any

interface Module extends EmscriptenModule {
  callMain(): void;
  _create_context(
    licenseKeyPointer: number,
    writableDataPathPointer: number,
    delayedRegistration: boolean,
    highEndBlurryRecognition: boolean,
    resourceFilesDataPathPointer: number,
    debug: boolean
  ): void;
  _scanner_settings_new_from_json(
    barcodeJsonSettingsPointer: number,
    textJsonSettingsPointer: number,
    barcodeCaptureEnabled: boolean,
    textCaptureEnabled: boolean,
    blurryRecognitionEnabled: boolean,
    matrixScanEnabled: boolean,
    highQualitySingleFrameMode: boolean,
    gpuEnabled: boolean
  ): number;
  _scanner_image_settings_new(width: number, height: number, channels: number): void;
  _scanner_session_clear(): void;
  _can_hide_logo(): number;
  _scanner_scan(imageDataPointer: number): number;
  _parser_parse_string(
    parserType: number,
    stringDataPointer: number,
    stringDataLength: number,
    optionsPointer: number
  ): number;

  _report_camera_properties(frontFacingDirection: boolean, adjustsFocus: boolean): void;
  _set_device_name(deviceNamePointer: number): void;
}

declare let Module: Module;

declare type ScanWorkUnit = {
  requestId: number;
  data: Uint8Array;
  highQualitySingleFrameMode: boolean;
};

declare type ParseWorkUnit = {
  requestId: number;
  dataFormat: Parser.DataFormat;
  data: string | Uint8Array;
  options: string;
};

// tslint:disable:no-reserved-keywords
/**
 * @hidden
 */
export declare type DataCaptureReceivedMessageData =
  | {
      type: "load-library";
      deviceId: string;
      libraryLocation: string;
      path: string;
      preload: boolean;
      delayedRegistration: boolean;
      highEndBlurryRecognition: boolean;
      textRecognition: boolean;
      licenseKey?: string;
      deviceModelName?: string;
      writableDataPathOverride?: string;
    }
  | {
      type: "scan-settings";
      settings: string;
      blurryRecognitionAvailable: boolean;
      blurryRecognitionRequiresUpdate: boolean;
    }
  | { type: "image-settings"; imageSettings: ImageSettings }
  | {
      type: "scan-image";
      requestId: number;
      data: Uint8Array;
      highQualitySingleFrameMode: boolean;
    }
  | { type: "parse"; requestId: number; dataFormat: Parser.DataFormat; data: string | Uint8Array; options: string }
  | { type: "clear-session" }
  | { type: "camera-properties"; cameraType: Camera.Type; autofocus: boolean }
  | { type: "device-name"; deviceName: string }
  | { type: "reset" };
// tslint:enable:no-reserved-keywords
/**
 * @hidden
 */
export declare type DataCaptureSentMessageData =
  // tslint:disable-next-line: no-any
  | ["log", { level: Exclude<Logger.Level, Logger.Level.QUIET>; data: any[] }]
  | ["library-loaded"]
  | ["context-created", object]
  | ["work-result", { requestId: number; result: { barcodes: BarcodeWASMResult[]; texts: string[] } }, Uint8Array]
  | ["work-error", { requestId: number; error: { errorCode: number; errorMessage: string } }, Uint8Array]
  | ["parse-result", { requestId: number; result: string }]
  | ["parse-error", { requestId: number; error: { errorCode: number; errorMessage: string } }]
  | ["reset"];

/**
 * @hidden
 */
export interface DataCaptureWorker extends Worker {
  onmessage: ((this: Worker, ev: MessageEvent & { data: DataCaptureSentMessageData }) => void) | null;
  postMessage(message: DataCaptureReceivedMessageData, transfer: Transferable[]): void;
  postMessage(
    message: DataCaptureReceivedMessageData,
    options?: {
      // tslint:disable-next-line: no-any
      transfer?: any[];
    } // Use custom object instead of PostMessageOptions to support TypeScript < 3.5
  ): void;
}

/**
 * @hidden
 */
export declare type DataCapture = {
  loadLibrary(
    deviceId: string,
    libraryLocation: string,
    locationPath: string,
    preload: boolean,
    delayedRegistration: boolean,
    highEndBlurryRecognition: boolean,
    textRecognition: boolean,
    licenseKey?: string,
    deviceModelName?: string,
    writableDataPathOverride?: string
  ): Promise<void>;
  setScanSettings(
    newScanSettings: string,
    blurryRecognitionAvailable: boolean,
    blurryRecognitionRequiresUpdate: boolean
  ): void;
  setImageSettings(imageSettings: ImageSettings): void;
  workOnScanQueue(): void;
  workOnParseQueue(): void;
  addScanWorkUnit(scanWorkUnit: ScanWorkUnit): void;
  addParseWorkUnit(parseWorkUnit: ParseWorkUnit): void;
  clearSession(): void;
  setCameraProperties(cameraType: Camera.Type, autofocus: boolean): void;
  setDeviceName(deviceName: string): void;
  reset(): void;
};

/**
 * @hidden
 *
 * @returns DataCapture
 */
// tslint:disable-next-line:max-func-body-length
export function dataCapture(): DataCapture {
  const writableDataPathPreload: string = "/scandit_sync_folder_preload";
  const writableDataPathStandard: string = "/scandit_sync_folder";
  const resourceFilesSubfolder: string = "resources";
  const scanQueue: ScanWorkUnit[] = [];
  const parseQueue: ParseWorkUnit[] = [];
  const gpuAccelerationAvailable: boolean = typeof self.OffscreenCanvas === "function";

  let originalFSSyncfs: typeof FS.syncfs | undefined;
  let imageBufferPointer: number | undefined;
  let imageBufferSize: number | undefined;
  let preloading: boolean;
  let writableDataPath: string;
  let resourceFilesDataPath: string;
  let delayedRegistration: boolean | undefined;
  let highEndBlurryRecognition: boolean;
  let licenseKey: string | undefined;
  let scanSettings: string | undefined;
  let imageSettings: ImageSettings | undefined;
  let recognitionMode: ScanSettings.RecognitionMode | undefined;
  let cameraProperties: { cameraType: Camera.Type; autofocus: boolean } | undefined;
  let deviceName: string | undefined;
  let blurryRecognitionAvailable: boolean = false;
  let workSubmitted: boolean = false;
  let loadingInProgress: boolean = false;
  let fileSystemSynced: boolean = false;
  let runtimeLoaded: boolean = false;
  let wasmReady: boolean = false;
  let scannerSettingsReady: boolean = false;
  let scannerImageSettingsReady: boolean = false;
  let contextAvailable: boolean = false;
  let fsSyncPromise: Promise<void> = Promise.resolve();
  let fsSyncInProgress: boolean = false;
  let fsSyncScheduled: boolean = false;

  // Public

  // Promise result is used only during testing
  // tslint:disable-next-line: parameters-max-number
  function loadLibrary(
    deviceId: string,
    libraryLocation: string,
    locationPath: string,
    preload: boolean,
    newDelayedRegistration: boolean,
    newHighEndBlurryRecognition: boolean,
    textRecognition: boolean,
    newLicenseKey?: string,
    deviceModelName?: string,
    writableDataPathOverride?: string
  ): Promise<void> {
    function reportLoadSuccess(): void {
      postMessage(["library-loaded"]);
      createContext(newDelayedRegistration, newHighEndBlurryRecognition, newLicenseKey);
    }

    function start(): void {
      if (!wasmReady && fileSystemSynced && runtimeLoaded) {
        loadingInProgress = false;
        Module.callMain();
        wasmReady = true;
        reportLoadSuccess();
        if (!newDelayedRegistration) {
          workOnScanQueue();
          workOnParseQueue();
        }
      }
    }

    if (loadingInProgress) {
      return Promise.resolve();
    }

    if (wasmReady) {
      reportLoadSuccess();

      return Promise.resolve();
    }

    loadingInProgress = true;

    // Sample WebAssembly WAT SIMD Program, containing i8x16.popcnt as part of one of the latest SIMD opcodes
    // (module
    //   (func (result v128)
    //     i32.const 1
    //     i8x16.splat
    //     i8x16.popcnt
    //   )
    // )
    const simdSupport: boolean = WebAssembly.validate(
      new Uint8Array([
        0, 97, 115, 109, 1, 0, 0, 0, 1, 5, 1, 96, 0, 1, 123, 3, 2, 1, 0, 10, 10, 1, 8, 0, 65, 1, 253, 15, 253, 98, 11,
      ])
    );

    const { jsURI, wasmURI } = getLibraryLocationURIs(libraryLocation, textRecognition, simdSupport);
    preloading = preload;
    if (preload) {
      writableDataPath = writableDataPathPreload;
    } else {
      if (writableDataPathOverride != null) {
        log(
          "debug",
          `IndexedDB database name override in use to recover from blocked standard database: ${writableDataPathOverride}`
        );
        writableDataPath = writableDataPathOverride;
      } else {
        writableDataPath = writableDataPathStandard;
      }
    }
    resourceFilesDataPath = `${libraryLocation}${resourceFilesSubfolder}/`;
    highEndBlurryRecognition = newHighEndBlurryRecognition;
    self.window = self.document = self; // Fix some Emscripten quirks
    self.path = locationPath; // Used by the Scandit Data Capture library
    self.deviceModelName = deviceModelName; // Used by the Scandit Data Capture library
    Module = <Module>(<unknown>{
      arguments: [deviceId],
      canvas: gpuAccelerationAvailable
        ? new self.OffscreenCanvas(32, 32)
        : /* istanbul ignore next */ {
            getContext: () => {
              return null;
            },
          },
      instantiateWasm: (importObject: object, successCallback: (instance: WebAssembly.Instance) => void) => {
        // wasmJSVersion is globally defined inside scandit-engine-sdk.min.js
        const wasmJSVersion: string = self.wasmJSVersion ?? "undefined";
        // istanbul ignore if
        if (wasmJSVersion !== "%VERSION%") {
          log(
            "error",
            `The Scandit Data Capture library JS file found at ${jsURI} seems invalid: ` +
              `expected version doesn't match (received: ${wasmJSVersion}, expected: ${"%VERSION%"}). ` +
              `Please ensure the correct Scandit Data Capture file (with correct version) is retrieved.`
          );
        }

        if (typeof self.WebAssembly.instantiateStreaming === "function") {
          instantiateWebAssemblyStreaming(importObject, wasmURI, textRecognition, simdSupport, successCallback);
        } else {
          instantiateWebAssembly(importObject, wasmURI, textRecognition, simdSupport, successCallback);
        }

        return {};
      },
      noInitialRun: true,
      preRun: [
        () => {
          return setupFS()
            .catch((error) => {
              log("debug", "No IndexedDB support, some data will not be persisted:", error);
            })
            .then(() => {
              fileSystemSynced = true;
              start();
            });
        },
      ],
      onRuntimeInitialized: () => {
        runtimeLoaded = true;
        start();
      },
      // tslint:disable-next-line: no-any
      print: (...data: any[]) => {
        log("info", ...data);
      },
      // tslint:disable-next-line: no-any
      printErr: (...data: any[]) => {
        log("error", ...data);
      },
    });

    function tryImportScripts(): Promise<void> {
      try {
        return importScripts(jsURI) ?? Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return retryWithExponentialBackoff(tryImportScripts, 250, 4000, (error) => {
      log("warn", error);
      log("warn", `Couldn't retrieve Scandit Data Capture library at ${jsURI}, retrying...`);
    }).catch((error) => {
      log("error", error);
      log(
        "error",
        `Couldn't retrieve Scandit Data Capture library at ${jsURI}, did you configure the path for it correctly?`
      );

      return Promise.resolve(error); // Promise is used only during testing
    });
  }

  function createContext(
    // tslint:disable-next-line: bool-param-default
    newDelayedRegistration?: boolean,
    // tslint:disable-next-line: bool-param-default
    newHighEndBlurryRecognition?: boolean,
    newLicenseKey?: string
  ): void {
    function completeCreateContext(): void {
      postMessage([
        "context-created",
        {
          hiddenScanditLogoAllowed: Module._can_hide_logo() === 1,
        },
      ]);
    }

    if (contextAvailable) {
      return completeCreateContext();
    }

    if (newDelayedRegistration != null) {
      delayedRegistration = newDelayedRegistration;
    }
    if (newHighEndBlurryRecognition != null) {
      highEndBlurryRecognition = newHighEndBlurryRecognition;
    }
    if (newLicenseKey != null) {
      licenseKey = newLicenseKey;
    }
    if (
      !wasmReady ||
      delayedRegistration == null ||
      highEndBlurryRecognition == null ||
      (!workSubmitted && !delayedRegistration) ||
      licenseKey == null
    ) {
      return;
    }

    const licenseKeyLength: number = lengthBytesUTF8(licenseKey) + 1;
    const licenseKeyPointer: number = Module._malloc(licenseKeyLength);
    stringToUTF8(licenseKey, licenseKeyPointer, licenseKeyLength);
    const writableDataPathLength: number = lengthBytesUTF8(writableDataPath) + 1;
    const writableDataPathPointer: number = Module._malloc(writableDataPathLength);
    stringToUTF8(writableDataPath, writableDataPathPointer, writableDataPathLength);
    const resourceFilesDataPathLength: number = lengthBytesUTF8(resourceFilesDataPath) + 1;
    const resourceFilesDataPathPointer: number = Module._malloc(resourceFilesDataPathLength);
    stringToUTF8(resourceFilesDataPath, resourceFilesDataPathPointer, resourceFilesDataPathLength);
    Module._create_context(
      licenseKeyPointer,
      writableDataPathPointer,
      delayedRegistration,
      highEndBlurryRecognition,
      resourceFilesDataPathPointer,
      false
    );
    Module._free(licenseKeyPointer);
    Module._free(writableDataPathPointer);
    Module._free(resourceFilesDataPathPointer);

    contextAvailable = true;

    reportCameraProperties();
    reportDeviceName();
    completeCreateContext();
  }

  function setScanSettings(
    newScanSettings: string,
    newBlurryRecognitionAvailable: boolean,
    blurryRecognitionRequiresUpdate: boolean
  ): void {
    function completeSetScanSettings(): void {
      scanSettings = newScanSettings;
      blurryRecognitionAvailable = newBlurryRecognitionAvailable;
      applyScanSettings();
      workOnScanQueue();
    }

    scanSettings = undefined;
    scannerSettingsReady = false;

    if (newBlurryRecognitionAvailable && blurryRecognitionRequiresUpdate) {
      syncFS(true, false, true).then(completeSetScanSettings).catch(completeSetScanSettings);
    } else {
      completeSetScanSettings();
    }
  }

  function setImageSettings(newImageSettings: ImageSettings): void {
    imageSettings = newImageSettings;
    applyImageSettings();
    workOnScanQueue();
  }

  function augmentErrorInformation(error: { errorCode: number; errorMessage: string }): void {
    if (error.errorCode === 260) {
      let hostname: string;
      // istanbul ignore if
      if (location.href?.indexOf("blob:null/") === 0) {
        hostname = "localhost";
      } else {
        hostname = new URL(
          location.pathname != null && location.pathname !== "" && !location.pathname.startsWith("/")
            ? /* istanbul ignore next */ location.pathname
            : location.origin
        ).hostname;
      }
      // istanbul ignore next
      if (hostname.startsWith("[") && hostname.endsWith("]")) {
        hostname = hostname.slice(1, -1);
      }
      error.errorMessage = error.errorMessage.replace("domain name", `domain name (${hostname})`);
    }

    // License Key related error codes from 6 to 25 and 260
    if (
      [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 260].includes(error.errorCode) &&
      licenseKey != null &&
      licenseKey.length > 0
    ) {
      error.errorMessage += ` License Key: ${licenseKey.slice(0, 15)}...`;
    }
  }

  function processScanWorkUnit(scanWorkUnit: ScanWorkUnit): void {
    if (scanWorkUnit.highQualitySingleFrameMode) {
      applyScanSettings(true);
    }
    const resultData: string = scanImage(scanWorkUnit.data);
    if (scanWorkUnit.highQualitySingleFrameMode) {
      applyScanSettings(false);
    }
    const result: {
      barcodeResult?: BarcodeWASMResult[];
      textResult?: string[];
      error?: { errorCode: number; errorMessage: string };
    } = JSON.parse(resultData);
    if (result.error != null) {
      augmentErrorInformation(result.error);
      postMessage(
        [
          "work-error",
          {
            requestId: scanWorkUnit.requestId,
            error: result.error,
          },
          scanWorkUnit.data,
        ],
        [scanWorkUnit.data.buffer]
      );
    } else {
      // istanbul ignore else
      if (result.barcodeResult != null && result.textResult != null) {
        postMessage(
          [
            "work-result",
            {
              requestId: scanWorkUnit.requestId,
              result: {
                barcodes: result.barcodeResult,
                texts: result.textResult,
              },
            },
            scanWorkUnit.data,
          ],
          [scanWorkUnit.data.buffer]
        );
      }
    }
  }

  function workOnScanQueue(): void {
    if (!wasmReady || scanQueue.length === 0) {
      return;
    }

    // Initialization for first submitted work unit
    if (!contextAvailable) {
      createContext();
    }
    if (!scannerSettingsReady) {
      applyScanSettings();
    }
    if (!scannerImageSettingsReady) {
      applyImageSettings();
    }

    if (!contextAvailable || !scannerSettingsReady || !scannerImageSettingsReady) {
      return;
    }

    while (scanQueue.length !== 0) {
      if (
        scanQueue[0].highQualitySingleFrameMode &&
        // TODO: For now it's not possible to use imported variables as the worker doesn't have access at runtime
        recognitionMode?.includes("code") === true &&
        !blurryRecognitionAvailable
      ) {
        break;
      }
      processScanWorkUnit(<ScanWorkUnit>scanQueue.shift());
    }
  }

  function processParseWorkUnit(parseWorkUnit: ParseWorkUnit): void {
    const resultData: string = parse(parseWorkUnit.dataFormat, parseWorkUnit.data, parseWorkUnit.options);
    const result: { result?: string; error?: { errorCode: number; errorMessage: string } } = JSON.parse(resultData);
    if (result.error != null) {
      augmentErrorInformation(result.error);
      postMessage([
        "parse-error",
        {
          requestId: parseWorkUnit.requestId,
          error: result.error,
        },
      ]);
    } else {
      // istanbul ignore else
      if (result.result != null) {
        postMessage([
          "parse-result",
          {
            requestId: parseWorkUnit.requestId,
            result: result.result,
          },
        ]);
      }
    }
  }

  function workOnParseQueue(): void {
    if (!wasmReady || parseQueue.length === 0) {
      return;
    }

    // Initialization for first submitted work unit
    if (!contextAvailable) {
      createContext();
      if (!contextAvailable) {
        return;
      }
    }

    while (parseQueue.length !== 0) {
      processParseWorkUnit(<ParseWorkUnit>parseQueue.shift());
    }
  }

  function addScanWorkUnit(scanWorkUnit: ScanWorkUnit): void {
    workSubmitted = true;
    scanQueue.push(scanWorkUnit);
    workOnScanQueue();
  }

  function addParseWorkUnit(parseWorkUnit: ParseWorkUnit): void {
    workSubmitted = true;
    parseQueue.push(parseWorkUnit);
    workOnParseQueue();
  }

  function clearSession(): void {
    if (scannerSettingsReady) {
      Module._scanner_session_clear();
    }
  }

  function setCameraProperties(cameraType: Camera.Type, autofocus: boolean): void {
    cameraProperties = {
      cameraType,
      autofocus,
    };
    reportCameraProperties();
  }

  function setDeviceName(newDeviceName: string): void {
    if (deviceName !== newDeviceName) {
      deviceName = newDeviceName;
      reportDeviceName();
    }
  }

  function reset(): void {
    clearSession();
    scanQueue.length = 0;
    parseQueue.length = 0;
    scanSettings = undefined;
    imageSettings = undefined;
    workSubmitted = false;
    scannerSettingsReady = false;
    scannerImageSettingsReady = false;
  }

  // Private

  // TODO: For now it's not possible to use imported variables as the worker doesn't have access at runtime
  // tslint:disable-next-line: no-any
  function log(level: "debug" | "info" | "warn" | "error", ...data: any[]): void {
    data.forEach((dataArgument, index) => {
      if (dataArgument instanceof Error) {
        const errorObject: Error = {
          name: dataArgument.name,
          message: dataArgument.message,
          stack: dataArgument.stack,
        };
        data[index] = errorObject;
      }
    });
    postMessage([
      "log",
      {
        level: <Exclude<Logger.Level, Logger.Level.QUIET>>level,
        data,
      },
    ]);
  }

  function retryWithExponentialBackoff<T>(
    handler: () => Promise<T>,
    backoffMs: number,
    maxBackoffMs: number,
    singleTryRejectionCallback: (error: Error) => void
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      handler()
        .then(resolve)
        .catch((error) => {
          const newBackoffMs: number = backoffMs * 2;
          if (newBackoffMs > maxBackoffMs) {
            return reject(error);
          }
          singleTryRejectionCallback(error);
          setTimeout(() => {
            retryWithExponentialBackoff(handler, newBackoffMs, maxBackoffMs, singleTryRejectionCallback)
              .then(resolve)
              .catch(reject);
          }, backoffMs);
        });
    });
  }

  function getLibraryLocationURIs(
    libraryLocation: string,
    textRecognition: boolean,
    simdSupport: boolean
  ): { jsURI: string; wasmURI: string } {
    let cdnURI: boolean = false;

    if (/^https?:\/\/([^\/.]*\.)*cdn.jsdelivr.net\//.test(libraryLocation)) {
      libraryLocation = "https://cdn.jsdelivr.net/npm/scandit-sdk@%VERSION%/build/";
      cdnURI = true;
    } else if (/^https?:\/\/([^\/.]*\.)*unpkg.com\//.test(libraryLocation)) {
      libraryLocation = "https://unpkg.com/scandit-sdk@%VERSION%/build/";
      cdnURI = true;
    }

    let fileExtension: string = "";
    if (textRecognition) {
      fileExtension += "-ocr";
      if (simdSupport) {
        fileExtension += "-simd";
      }
    }

    if (cdnURI) {
      return {
        jsURI: `${libraryLocation}scandit-engine-sdk${fileExtension}.min.js`,
        wasmURI: `${libraryLocation}scandit-engine-sdk${fileExtension}.wasm`,
      };
    }

    return {
      jsURI: `${libraryLocation}scandit-engine-sdk${fileExtension}.min.js?v=%VERSION%`,
      wasmURI: `${libraryLocation}scandit-engine-sdk${fileExtension}.wasm?v=%VERSION%`,
    };
  }

  function arrayBufferToHexString(arrayBuffer: ArrayBuffer): string {
    return Array.from(new Uint8Array(arrayBuffer))
      .map((byteNumber) => {
        const byteHex: string = byteNumber.toString(16);

        return byteHex.length === 1 ? /* istanbul ignore next */ `0${byteHex}` : byteHex;
      })
      .join("");
  }

  function applyScanSettings(highQualitySingleFrameMode: boolean = false): void {
    if (!wasmReady || !contextAvailable || !workSubmitted || scanSettings == null) {
      return;
    }

    scannerSettingsReady = false;

    const parsedSettings: {
      recognitionMode?: ScanSettings.RecognitionMode;
      textRecognitionSettings?: string;
      matrixScanEnabled?: boolean;
      gpuAcceleration?: boolean;
      blurryRecognition?: boolean;
    } = JSON.parse(scanSettings);
    recognitionMode = parsedSettings.recognitionMode;
    parsedSettings.textRecognitionSettings ??= JSON.stringify({});
    const scanSettingsLength: number = lengthBytesUTF8(scanSettings) + 1;
    const scanSettingsPointer: number = Module._malloc(scanSettingsLength);
    stringToUTF8(scanSettings, scanSettingsPointer, scanSettingsLength);
    const textRecognitionSettingsLength: number = lengthBytesUTF8(parsedSettings.textRecognitionSettings) + 1;
    const textRecognitionSettingsPointer: number = Module._malloc(textRecognitionSettingsLength);
    stringToUTF8(parsedSettings.textRecognitionSettings, textRecognitionSettingsPointer, textRecognitionSettingsLength);
    const resultPointer: number = Module._scanner_settings_new_from_json(
      scanSettingsPointer,
      textRecognitionSettingsPointer,
      // TODO: For now it's not possible to use imported variables as the worker doesn't have access at runtime
      recognitionMode?.includes("code") === true,
      recognitionMode?.includes("text") === true,
      parsedSettings.blurryRecognition === true && blurryRecognitionAvailable,
      parsedSettings.matrixScanEnabled ?? false,
      highQualitySingleFrameMode,
      parsedSettings.gpuAcceleration === true && gpuAccelerationAvailable
    );
    Module._free(scanSettingsPointer);

    const result: string = UTF8ToString(resultPointer);
    if (result !== "") {
      scannerSettingsReady = true;
      log("debug", "External Scandit Data Capture scan settings:", JSON.parse(result));
    }
  }

  function applyImageSettings(): void {
    if (!wasmReady || !contextAvailable || !workSubmitted || imageSettings == null) {
      return;
    }

    scannerImageSettingsReady = false;

    let channels: number;
    // TODO: For now it's not possible to use imported variables as the worker doesn't have access at runtime
    if (imageSettings.format.valueOf() === 1) {
      // RGB_8U
      channels = 3;
    } else if (imageSettings.format.valueOf() === 2) {
      // RGBA_8U
      channels = 4;
    } else {
      // GRAY_8U
      channels = 1;
    }
    Module._scanner_image_settings_new(imageSettings.width, imageSettings.height, channels);
    if (imageBufferPointer != null) {
      Module._free(imageBufferPointer);
    }
    imageBufferSize = imageSettings.width * imageSettings.height * channels;
    imageBufferPointer = Module._malloc(imageBufferSize);

    scannerImageSettingsReady = true;
  }

  function reportCameraProperties(): void {
    if (!wasmReady || !contextAvailable || cameraProperties == null) {
      return;
    }
    // TODO: For now it's not possible to use imported variables as the worker doesn't have access at runtime
    Module._report_camera_properties(cameraProperties.cameraType === "front", cameraProperties.autofocus);
  }

  function reportDeviceName(): void {
    if (!wasmReady || !contextAvailable || deviceName == null) {
      return;
    }
    const deviceNameLength: number = lengthBytesUTF8(deviceName) + 1;
    const deviceNamePointer: number = Module._malloc(deviceNameLength);
    stringToUTF8(deviceName, deviceNamePointer, deviceNameLength);
    Module._set_device_name(deviceNamePointer);
    Module._free(deviceNamePointer);
  }

  function scanImage(imageData: Uint8Array): string {
    if (imageData.byteLength !== imageBufferSize) {
      // This could happen in unexpected situations and should be temporary
      return JSON.stringify({ barcodeResult: [], textResult: [] });
    }

    Module.HEAPU8.set(imageData, imageBufferPointer);

    return UTF8ToString(Module._scanner_scan(<number>imageBufferPointer));
  }

  function parse(dataFormat: Parser.DataFormat, data: string | Uint8Array, options: string): string {
    const dataLength: number = typeof data === "string" ? lengthBytesUTF8(data) + 1 : data.byteLength;
    const dataPointer: number = Module._malloc(dataLength);
    if (typeof data === "string") {
      stringToUTF8(data, dataPointer, dataLength);
    } else {
      Module.HEAPU8.set(data, dataPointer);
    }
    const optionsLength: number = lengthBytesUTF8(options) + 1;
    const optionsPointer: number = Module._malloc(optionsLength);
    stringToUTF8(options, optionsPointer, optionsLength);
    const resultPointer: number = Module._parser_parse_string(
      dataFormat.valueOf(),
      dataPointer,
      dataLength - 1,
      optionsPointer
    );
    Module._free(dataPointer);
    Module._free(optionsPointer);

    return UTF8ToString(resultPointer);
  }

  function verifiedWasmFetch(
    wasmURI: string,
    textRecognition: boolean,
    simdSupport: boolean,
    awaitFullResponse: boolean
  ): Promise<Response> {
    function verifyResponseData(responseData: ArrayBuffer): void {
      // istanbul ignore else
      if (typeof crypto?.subtle?.digest === "function") {
        crypto.subtle
          .digest("SHA-256", responseData)
          .then((hash) => {
            const hashString: string = arrayBufferToHexString(hash);
            let expectedHashString: string = "%SDC_WASM_HASH%";
            if (textRecognition) {
              expectedHashString = simdSupport ? "%SDC_OCR_SIMD_WASM_HASH%" : "%SDC_OCR_WASM_HASH%";
            }

            // istanbul ignore if
            if (hashString !== expectedHashString) {
              log(
                "error",
                `The Scandit Data Capture library WASM file found at ${wasmURI} seems invalid: ` +
                  `expected file hash doesn't match (received: ${hashString}, ` +
                  `expected: ${expectedHashString}). ` +
                  `Please ensure the correct Scandit Data Capture file (with correct version) is retrieved.`
              );
            }
          })
          .catch(
            /* istanbul ignore next */ () => {
              // Ignored
            }
          );
      } else {
        log(
          "warn",
          "Insecure context (see https://developer.mozilla.org/en-US/docs/Web/Security/Secure_Contexts): " +
            `The hash of the Scandit Data Capture library WASM file found at ${wasmURI} could not be verified`
        );
      }
    }

    function tryFetch(): Promise<Response> {
      return new Promise((resolve, reject) => {
        fetch(wasmURI)
          .then((response) => {
            // istanbul ignore else
            if (response.ok) {
              response
                .clone()
                .arrayBuffer()
                .then((responseData) => {
                  if (awaitFullResponse) {
                    resolve(response);
                  }
                  verifyResponseData(responseData);
                })
                .catch(
                  // istanbul ignore next
                  (error) => {
                    if (awaitFullResponse) {
                      reject(error);
                    }
                  }
                );

              if (!awaitFullResponse) {
                resolve(response);
              }
            } else {
              reject(new Error("HTTP status code is not ok"));
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    return retryWithExponentialBackoff(tryFetch, 250, 4000, (error) => {
      log("warn", error);
      log("warn", `Couldn't retrieve Scandit Data Capture library at ${wasmURI}, retrying...`);
    }).catch((error) => {
      log("error", error);
      log(
        "error",
        `Couldn't retrieve/instantiate Scandit Data Capture library at ${wasmURI}, ` +
          "did you configure the path for it correctly?"
      );

      return Promise.reject(error);
    });
  }

  function instantiateWebAssembly(
    importObject: object,
    wasmURI: string,
    textRecognition: boolean,
    simdSupport: boolean,
    successCallback: (instance: WebAssembly.Instance) => void,
    existingResponse?: Response
  ): void {
    (existingResponse != null
      ? Promise.resolve(existingResponse)
      : verifiedWasmFetch(wasmURI, textRecognition, simdSupport, true)
    )
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((bytes) => {
        return self.WebAssembly.instantiate(bytes, importObject)
          .then((results: WebAssembly.WebAssemblyInstantiatedSource) => {
            successCallback(results.instance);
          })
          .catch((error: Error) => {
            log("error", error);
            log(
              "error",
              `Couldn't instantiate Scandit Data Capture library at ${wasmURI}, ` +
                "did you configure the path for it correctly?"
            );
          });
      })
      .catch(
        /* istanbul ignore next */ () => {
          // Ignored
        }
      );
  }

  function instantiateWebAssemblyStreaming(
    importObject: object,
    wasmURI: string,
    textRecognition: boolean,
    simdSupport: boolean,
    successCallback: (instance: WebAssembly.Instance) => void
  ): void {
    verifiedWasmFetch(wasmURI, textRecognition, simdSupport, false)
      .then((response) => {
        self.WebAssembly.instantiateStreaming(response, importObject)
          .then((results: WebAssembly.WebAssemblyInstantiatedSource) => {
            successCallback(results.instance);
          })
          .catch((error: Error) => {
            log("warn", error);
            log(
              "warn",
              "WebAssembly streaming compile failed. " +
                "Falling back to ArrayBuffer instantiation (this will make things slower)"
            );
            instantiateWebAssembly(importObject, wasmURI, textRecognition, simdSupport, successCallback, response);
          });
      })
      .catch(
        /* istanbul ignore next */ () => {
          // Ignored
        }
      );
  }

  // tslint:disable-next-line: max-func-body-length
  function syncFSMergePreloadedData(): Promise<number> {
    // Note that this function is only executed by the non-preload WebWorker
    // (i.e.the one not created to only generate blurry tables)
    // This means that writableDataPath will always be defined to be writableDataPathStandard or its override
    const fsObjectStoreName: string = "FILE_DATA";
    let resolveCallback: (value: number | PromiseLike<number>) => void;
    let openDbSourceRequest: IDBOpenDBRequest;
    let openDbTargetRequest: IDBOpenDBRequest;
    let targetTransactionCompleteTimeout: number;

    function handleError(this: IDBOpenDBRequest | IDBTransaction | IDBRequest | { error: Error }): void {
      clearTimeout(targetTransactionCompleteTimeout);
      openDbSourceRequest?.result?.close();
      openDbTargetRequest?.result?.close();
      // this.error
      resolveCallback(0);
    }

    function performMerge(): void {
      try {
        const objects: { value: object; primaryKey: IDBValidKey }[] = [];
        const sourceTransaction: IDBTransaction = openDbSourceRequest.result.transaction(fsObjectStoreName, "readonly");
        sourceTransaction.onerror = sourceTransaction.onabort = handleError;
        const cursorRequest: IDBRequest<IDBCursorWithValue | null> = sourceTransaction
          .objectStore(fsObjectStoreName)
          .openCursor();
        cursorRequest.onsuccess = () => {
          const cursor: IDBCursorWithValue | null = cursorRequest.result;
          if (cursor == null) {
            try {
              let mergedObjectsCount: number = 0;
              const targetTransaction: IDBTransaction = openDbTargetRequest.result.transaction(
                fsObjectStoreName,
                "readwrite"
              );
              const targetObjectStore: IDBObjectStore = targetTransaction.objectStore(fsObjectStoreName);
              targetTransaction.onerror = targetTransaction.onabort = handleError;
              targetTransaction.oncomplete = () => {
                clearTimeout(targetTransactionCompleteTimeout);
                openDbSourceRequest.result.close();
                openDbTargetRequest.result.close();

                return resolveCallback(mergedObjectsCount);
              };
              for (const object of objects) {
                const countRequest: IDBRequest<number> = targetObjectStore.count(object.primaryKey);
                countRequest.onsuccess = () => {
                  if (countRequest.result === 0) {
                    ++mergedObjectsCount;
                    targetObjectStore.add(object.value, object.primaryKey);
                  }
                };
                // Due to Safari browser bugs, it can rarely happen that the transaction is never marked as complete,
                // when that happens the database is unfortunately completely stuck and can also not be deleted.
                // We then reset the library and use a completely new database with a new randomly generated name.
                clearTimeout(targetTransactionCompleteTimeout);
                targetTransactionCompleteTimeout = self.setTimeout(() => {
                  log("warn", "IndexedDB database is blocked! Resetting Scandit Data Capture library...");
                  postMessage(["reset"]);
                }, 500);
              }
            } catch (error) {
              // istanbul ignore next
              handleError.call({ error });
            }
          } else {
            objects.push({
              value: cursor.value,
              primaryKey: cursor.primaryKey.toString().replace(`${writableDataPathPreload}/`, `${writableDataPath}/`),
            });
            cursor.continue();
          }
        };
        cursorRequest.onerror = handleError;
      } catch (error) {
        // istanbul ignore next
        handleError.call({ error });
      }
    }

    return new Promise((resolve) => {
      resolveCallback = resolve;
      openDbSourceRequest = indexedDB.open(writableDataPathPreload);
      openDbSourceRequest.onupgradeneeded = () => {
        try {
          openDbSourceRequest.result.createObjectStore(fsObjectStoreName);
        } catch (error) {
          // Ignored
        }
      };
      openDbSourceRequest.onsuccess = () => {
        if (!Array.from(openDbSourceRequest.result.objectStoreNames).includes(fsObjectStoreName)) {
          return resolve(0);
        }

        openDbTargetRequest = indexedDB.open(writableDataPath);
        openDbTargetRequest.onupgradeneeded = () => {
          try {
            openDbTargetRequest.result.createObjectStore(fsObjectStoreName);
          } catch (error) {
            // Ignored
          }
        };
        openDbTargetRequest.onsuccess = () => {
          performMerge();
        };
        openDbTargetRequest.onblocked = openDbTargetRequest.onerror = handleError;
      };
      openDbSourceRequest.onblocked = openDbSourceRequest.onerror = handleError;
    });
  }

  function syncFSPromisified(populate: boolean, initialPopulation: boolean): Promise<void> {
    // istanbul ignore if
    if (originalFSSyncfs == null) {
      return Promise.resolve();
    }
    fsSyncInProgress = true;

    return new Promise((resolve, reject) => {
      // Merge with data coming from preloading workers if needed
      (!preloading && populate ? syncFSMergePreloadedData() : Promise.resolve(0))
        .then((mergedObjects) => {
          if (!preloading && populate && !initialPopulation && mergedObjects === 0) {
            fsSyncInProgress = false;

            return resolve();
          }
          // tslint:disable-next-line: no-non-null-assertion
          originalFSSyncfs!(populate, (error) => {
            fsSyncInProgress = false;
            // istanbul ignore if
            if (error != null) {
              return reject(error);
            }
            resolve();
          });
        })
        .catch(reject);
    });
  }

  function syncFS(
    populate: boolean,
    initialPopulation: boolean = false,
    forceScheduling: boolean = false
  ): Promise<void> {
    if (!fsSyncScheduled || forceScheduling) {
      if (fsSyncInProgress) {
        fsSyncScheduled = true;
        fsSyncPromise = fsSyncPromise.then(() => {
          fsSyncScheduled = false;

          return syncFSPromisified(populate, initialPopulation);
        });
      } else {
        fsSyncPromise = syncFSPromisified(populate, initialPopulation);
      }
    }

    return fsSyncPromise;
  }

  function setupFS(): Promise<void> {
    // FS.syncfs is also called by data capture on file storage, ensure everything is coordinated nicely
    originalFSSyncfs = FS.syncfs;
    FS.syncfs = <typeof FS.syncfs>((populate: boolean, callback: (e: Error | void) => void) => {
      const originalCallback: typeof callback = callback;
      callback = (error) => {
        originalCallback(error);
      };
      syncFS(populate).then(callback).catch(callback);
    });

    try {
      FS.mkdir(writableDataPath);
    } catch (error) {
      // istanbul ignore next
      if (error.code !== "EEXIST") {
        originalFSSyncfs = undefined;

        return Promise.reject(error);
      }
    }
    FS.mount(IDBFS, {}, writableDataPath);

    return syncFS(true, true);
  }

  return {
    loadLibrary,
    setScanSettings,
    setImageSettings,
    workOnScanQueue,
    workOnParseQueue,
    addScanWorkUnit,
    addParseWorkUnit,
    clearSession,
    setCameraProperties,
    setDeviceName,
    reset,
  };
}

// istanbul ignore next
function edataCaptureWorkerFunction(): void {
  const dataCaptureInstance: DataCapture = dataCapture();

  onmessage = (e) => {
    // Creating context triggers license key verification and activation: delay until first frame processed
    const data: DataCaptureReceivedMessageData = e.data;
    switch (data.type) {
      case "load-library":
        // tslint:disable-next-line: no-floating-promises
        dataCaptureInstance.loadLibrary(
          data.deviceId,
          data.libraryLocation,
          data.path,
          data.preload,
          data.delayedRegistration,
          data.highEndBlurryRecognition,
          data.textRecognition,
          data.licenseKey,
          data.deviceModelName,
          data.writableDataPathOverride
        );
        break;
      case "scan-settings":
        dataCaptureInstance.setScanSettings(
          data.settings,
          data.blurryRecognitionAvailable,
          data.blurryRecognitionRequiresUpdate
        );
        break;
      case "image-settings":
        dataCaptureInstance.setImageSettings(data.imageSettings);
        break;
      case "scan-image":
        dataCaptureInstance.addScanWorkUnit({
          requestId: data.requestId,
          data: data.data,
          highQualitySingleFrameMode: data.highQualitySingleFrameMode,
        });
        break;
      case "parse":
        dataCaptureInstance.addParseWorkUnit({
          requestId: data.requestId,
          dataFormat: data.dataFormat,
          data: data.data,
          options: data.options,
        });
        break;
      case "clear-session":
        dataCaptureInstance.clearSession();
        break;
      case "camera-properties":
        dataCaptureInstance.setCameraProperties(data.cameraType, data.autofocus);
        break;
      case "device-name":
        dataCaptureInstance.setDeviceName(data.deviceName);
        break;
      case "reset":
        dataCaptureInstance.reset();
        break;
      default:
        break;
    }
  };
}

/**
 * @hidden
 */
export const dataCaptureWorkerBlob: Blob = new Blob(
  [`var Module;${dataCapture.toString()}(${edataCaptureWorkerFunction.toString()})()`],
  {
    type: "text/javascript",
  }
);
